@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';

header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $header-background-desktop-colour;
    border-bottom: 1px solid $header-border-colour;
    z-index: 100;

    .wrapper {
        max-width: $wrapper-width;
        z-index: 200;

        @include respond-min($medium-device) {
            padding: 0 28px;
        }

        @include respond-min($extra-large-device) {
            padding: 0 20px;
        }

        .content {
            padding: 0 1em;
            height: $header-height-mobile;
            display: flex;
            align-items: center;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;

            @include respond-min($large-device) {
                height: $header-height-desktop;
            }
        }
    }

    .logo {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex: 1 1 0%;
        margin: 0;
        height: 40px;
        width: 100%;
        /*background: url(/assets/images/logo.png) no-repeat;*/
        z-index: 3;
        text-decoration: none;

        @include respond-min($medium-device) {
            height: 60px;
        }

        @include respond-min($large-device) {
            height: 90px;
        }

        .main {
            background: url(/assets/images/logo.png) no-repeat;
            background-position: center left;
            background-size: contain;
            display: block;
            height: 100%;
            width: 50%;

            &:hover {
                text-decoration: none;
            }
        }

        a.tribute {
            /*margin-left: 25px;*/
            font-family: "franklin-gothic-ext-cond",sans-serif;
            letter-spacing: 3.5px;
            font-size: 22px;
            text-transform: uppercase;
            text-align: center;
            text-decoration:none;
            width: 100%;
            color: $header-foreground-desktop-colour;
            /*@include respond-max($small-mobile) {
                font-size: 18px;
                margin-left: 20%;
            }*/
            @include respond-min($medium-device) {
                font-size: 28px;
            }

            @include respond-min($large-device) {
                /*margin-left: 140px;*/
                font-size: 34px;
            }
        }
    }

    .menu-button {
        flex: 0 1 auto;
        margin: 0;
        height: 50px;
        width: 50px;
        background: url(/assets/images/icons/menu.svg) no-repeat #F5F5F5;
        background-position: center;
        background-size: 22px;
        z-index: 3;

        span {
            margin-left: 26px;
            font-size: 16px;
            line-height: 15px;
        }

        @include respond-min($large-device) {
            display: none;
        }
    }

    .collapse:not(.show) {
        display: none;

        @include respond-min($large-device) {
            display: flex;
        }
    }

    nav {
        position: absolute;
        flex: 0 1 auto;
        margin: 0;
        padding: 0;
        top: $header-height-mobile;
        left: 0;
        right: auto;
        width: 100%;
        text-align: center;
        background-color: $header-background-mobile-colour;
        box-shadow: 0 4px 2px -2px $primary-textshadow-colour;

        @include respond-min($large-device) {
            position: relative;
            width: auto;
            height: 100%;
            top: 0;
            background-color: $header-background-desktop-colour;
            box-shadow: none;
        }

        ul {
            flex-direction: column;
            align-items: stretch;

            li {
                height: 100%;

                &.nav-link {
                    @include respond-min($large-device) {
                        &.hide {
                            display: none;
                        }
                    }

                    @include respond-min($extra-large-device) {
                        &.hide {
                            display: block;
                        }
                    }

                    a > span {
                        color: #fff;

                        @include respond-min($large-device) {
                            color: #000;
                        }
                    }
                }
            }

            @include respond-min($large-device) {
                flex-direction: row;
                align-items: center;

                li {
                    padding: 0;

                    &:hover {
                        & > .dropdown-menu-wrapper {
                            display: block;
                        }
                    }
                }
            }
        }

        a {
            display: flex;
            padding: 14px;
            height: 100%;
            align-items: center;
            justify-content: flex-start;
            font-family: "franklin-gothic-urw-cond-book",sans-serif;
            font-size: 19px;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            color: $header-foreground-mobile-colour;

            @include respond-min($large-device) {
                padding: 0 2em;
                justify-content: flex-end;
                color: $header-foreground-desktop-colour;

                &.active {
                    font-weight: bold;
                }
            }

            &:hover {
                text-decoration: none;
            }

            &.show-menu {
                background-color: $header-background-desktop-colour;
                color: $header-foreground-desktop-colour;

                & + .dropdown-menu-wrapper {
                    display: block;

                    @include respond-min($medium-device) {
                        display: none;
                    }
                }
            }
        }

        .dropdown-menu-wrapper {
            display: none;
            position: relative;
            background-color: #ffffff;

            @include respond-min($medium-device) {
                position: absolute;
                background-color: transparent;
            }

            .dropdown-menu {
                position: relative;
                display: block;
                min-width: 270px;
                z-index: 1;

                @include respond-min($medium-device) {
                    margin-top: 20px;
                    padding: 15px;
                    background-color: #fff;
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

                    &:after {
                        margin-top: -10px;
                        position: absolute;
                        top: 1px;
                        left: 21%;
                        height: 0;
                        width: 0;
                        border: solid transparent;
                        border-color: rgba(255, 255, 255, 0);
                        border-left-color: white;
                        border-top-color: white;
                        border-width: 10px;
                        box-shadow: -6px -6px 10px -5px rgba(55, 55, 56, 0.2);
                        content: ' ';
                        pointer-events: none;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }

                li {
                    padding: 14px;

                    & + li {
                        margin: 0;
                    }

                    a {
                        padding: 0;
                        border: 0;
                        justify-content: flex-start;
                        color: $header-foreground-desktop-colour;
                        text-transform: none;

                        &:after {
                            display: none;
                        }

                        &:hover {
                            border: 0;
                        }
                    }
                }
            }
        }

        .search-box {
            margin: 14px;
            display: flex;
            align-items: center;
            background-color: #ffffff;
            //border: 1px solid $primary-border-colour;
            position: relative;

            @include respond-min($large-device) {
                margin: 35px 0;
                background: #ffffff;
                border: none;
            }

            @include respond-min($extra-large-device) {
                //border: 1px solid #767676;
                background: none;
            }

            .search-button {
                display: block;
                //margin: 0;
                //height: 48px;
                //width: 48px;
                background-color: #F5F5F5;
                background-image: url(/assets/images/icons/magnifying-glass-black.svg);
                background-position: center;
                background-size: 22px;
                background-repeat: no-repeat;
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 47px;
                height: 100%;
                min-height: 47px;
                border: 1px solid $primary-border-colour;

                @include respond-min($large-device) {
                    position: relative;
                    border: none;
                    background-color: #373738;
                    background-image: url(/assets/images/icons/magnifying-glass-white.svg);

                    &.active {
                        position: absolute;
                        background-color: #F5F5F5;
                        background-image: url(/assets/images/icons/magnifying-glass-black.svg);
                    }
                }

                @include respond-min($extra-large-device) {
                    position: absolute;
                    border: 1px solid $primary-border-colour;
                    background-color: #F5F5F5;
                    background-image: url(/assets/images/icons/magnifying-glass-black.svg);
                }
            }

            .close-button {
                display: none;
                height: 50px;
                width: 48px;
                background-color: #373738;
                background-image: url(/assets/images/icons/close-white-icon.svg);
                background-position: center;
                background-size: 22px;
                background-repeat: no-repeat;
                content: '';

                @include respond-min($large-device) {
                    &.show {
                        display: block;
                    }
                }

                @include respond-min($extra-large-device) {
                    display: none;

                    &.show {
                        display: none; // fixes css device size issue
                    }
                }
            }

            form {
                display: block;
                width: 100%;
                border: 1px solid $primary-border-colour;
                width: calc(100% - 47px);
                border-right: none;
                margin-right: 47px;

                @include respond-min($large-device) {
                    display: none;
                    width: auto;

                    &.show {
                        display: block;
                    }
                }

                @include respond-min($extra-large-device) {
                    display: block;
                }

                & > input {
                    margin: 3px 1px;
                    padding: 11px;
                    height: 100%;
                    width: 100%;
                    border: 0;
                    font-size: 17px;

                    &:focus {
                        outline-width: 0;
                    }

                    @include respond-min($large-device) {
                        background-color: #ffffff;
                        width: 170px;
                    }

                    @include respond-min($extra-large-device) {
                        background-color: transparent;
                    }
                }
            }

            #tooltip {
                position: relative;
                display: inline-block;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            /* The actual popup */
            #tooltip .text, #tooltip .tip {
                visibility: hidden;
                background-color: rgba(255,255,255,1);
                color: #000;
                text-align: center;
                position: absolute;
                z-index: 1;
                top: 40px;
                font-size: .9em;
                line-height: 20px;
                padding: 5px 0;
                height: 52px;
            }
            /* Popup arrow */
            #tooltip .tip {
                width: 30px;
                left: -210px;
                color: #fff;
                z-index: 2;
                font-size: 1.2em;
                height: 30px;
                padding: 5px;
                background-color: #ffa300;
                top: 50px;
                font-weight: 700;
                font-family: sans-serif;
            }

            #tooltip .text {
                left: -220px;
                border-radius: 6px;
                width: 220px;
                border: 1px solid #999;
                padding-left: 40px;
            }

            #tooltip .text::before {
                content: "";
                position: absolute;
                top: calc(22% * -1);
                left: 8%;
                margin: 0;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent #ffa300 transparent;
            }

            #tooltip.show .text, #tooltip.show .tip {
                visibility: visible;
                -webkit-animation: fadeInOut 3s;
                animation: fadeIn 1s;
            }

            @-webkit-keyframes fadeInOut {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }

            @keyframes fadeInOut {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }

            #tooltip .hide {
                visibility: hidden;
                -webkit-animation: ease-in-out 3s;
                animation: fadeInOut 3s;
            }
        }
    }
}
