@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';
@import '../base/mixins.scss';

.search {
    .search-results {
        margin: 1em 0;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-content: center;
        //align-items: center;
        a {
            text-decoration: none;
        }

        .search-results-item {
            flex: 1 1 auto;
            padding: 1em 0;
            border-bottom: 2px solid #ECEBEB;

            a {
                margin: 0 auto;
                width: 180px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                align-items: center;
            }

            .image {
                margin-bottom: 1em;
                height: 180px;
                width: 180px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }

            .type-fund, .type-event {
                position: relative;

                &:after {
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    content: '';
                    filter: drop-shadow(0 0 4px #808080);
                }
            }

            .type-fund:after {
                @include image-icon($image-search-fund-icon, 48px, 52px);
            }

            .type-event:after {
                @include image-icon($image-search-event-icon, 48px, 52px);
            }

            .title {
                word-break: break-word;
                text-align: center;
                color: #000;
                width:100%;//ie fix
            }

            .total-raised {
                margin-top: 1em;
                @include account-rasied();
                text-align: center;
            }
        }

    }
}
