@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';

.fund-theme {
    background-position: center;
    background-repeat: repeat-y;
    background-size: cover;
}

.fund-theme-select {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    margin-top: 1.5em;
    max-width: 760px;

    .fund-theme-select-option {
        flex: 1 1 47%;
        position: relative;
        margin: 0 1em 1em 0;
        height: 202px;
        width: 100%;
        max-width: 360px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        input[type=radio] {
            display: none;

            &:checked {
                & + label:before {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-image: url("/assets/images/theme-selected-rectangle.svg");
                    content: '';
                }

                & + label:after {
                    display: block;
                    position: absolute;
                    bottom: 20px;
                    right: 6%;
                    height: 30px;
                    width: 31px;
                    border-radius: 50%;
                    background-image: url("/assets/images/icons/selection-tick.svg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    content: '';
                }
            }
        }

        label {
            display: block;
            height: 100%;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}
