﻿$wrapper-width: 1280px;

// header
$header-height-desktop: 120px;
$header-height-mobile: 80px;

// button
$button-font-size: 20px;
$button-normal-padding: 0.7em 2em;

// paths
$path-images: '/assets/images';

// icons
$image-fund-header-icon: '#{$path-images}/icons/fund.svg';
$image-event-header-icon: '#{$path-images}/icons/event.svg';
$image-donation-header-icon: '#{$path-images}/icons/donation.svg';
$image-video-header-icon: '#{$path-images}/icons/video.svg';
$image-photo-header-icon: '#{$path-images}/icons/photo.svg';

$image-carousel-arrow-icon: '#{$path-images}/icons/carousel-arrow.svg';

$image-search-fund-icon: '#{$path-images}/icons/search/type-fund.svg';
$image-search-event-icon: '#{$path-images}/icons/search/type-event.svg';

$image-social-home-icon: '#{$path-images}/icons/soical/home.svg';
$image-social-twitter-icon: '#{$path-images}/icons/soical/twitter.svg';
$image-social-facebook-icon: '#{$path-images}/icons/soical/facebook.svg';
$image-social-instagram-icon: '#{$path-images}/icons/soical/instagram.svg';
$image-social-linkedin-icon: '#{$path-images}/icons/soical/linkedin.svg';
$image-social-pinterest-icon: '#{$path-images}/icons/soical/pinterest.svg';
$image-social-youtube-icon: '#{$path-images}/icons/soical/youtube.svg';
$image-social-email-icon: '#{$path-images}/icons/soical/email.svg';

$image-fund-facebook-icon: '#{$path-images}/icons/soical/facebook-black.svg';
$image-fund-blockquote-icon: '#{$path-images}/icons/blockquote.svg';

$image-no-events: '#{$path-images}/icons/no-events.svg';

$image-fundraising-regulator-logo: '#{$path-images}/fundraising-regulator-logo.svg';
$image-fundraising-regulator-dark-logo: '#{$path-images}/fundraising-regulator-dark-logo.svg';

$image-button-normal-photo: '#{$path-images}/icons/edit/photo-dark.svg';
$image-button-hover-photo: '#{$path-images}/icons/edit/photo-light.svg';

$image-button-normal-video: '#{$path-images}/icons/edit/video-dark.svg';
$image-button-hover-video: '#{$path-images}/icons/edit/video-light.svg';

$image-button-normal-update: '#{$path-images}/icons/edit/update-dark.svg';
$image-button-hover-update: '#{$path-images}/icons/edit/update-light.svg';

$image-button-normal-donation: '#{$path-images}/icons/edit/donation-dark.svg';
$image-button-hover-donation: '#{$path-images}/icons/edit/donation-light.svg';

$image-button-normal-card: '#{$path-images}/icons/edit/card-dark.svg';
$image-button-hover-card: '#{$path-images}/icons/edit/card-light.svg';

$image-validation-cross: '#{$path-images}/icons/validation/error-validation-cross.svg';

$image-input-select-arrow: '#{$path-images}/icons/inputs/down-arrow.svg';

// colours
$account-raised-text-colour: #011D49;

// notification
$notification-light-background: #ffffff;
$notification-dark-background: #F5F5F5;


// donation
$donation-amount-font-family: 'franklin-gothic-urw-demi';
$donation-amount-font-size: 22px;
