@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';
@import '../base/mixins.scss';

.social-links {
    margin: 1em 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .social-link {
        margin: 0 .25em;

        & + .social-link {
            margin-left: 1em;
        }
    }

    .home-icon {
        @include image-icon($image-social-home-icon, 45px, 45px);
    }

    .twitter-icon {
        @include image-icon($image-social-twitter-icon, 45px, 45px);
    }

    .facebook-icon {
        @include image-icon($image-social-facebook-icon, 45px, 45px);
    }

    .instagram-icon {
        @include image-icon($image-social-instagram-icon, 45px, 45px);
    }

    .linkedin-icon {
        @include image-icon($image-social-linkedin-icon, 45px, 45px);
    }

    .pinterest-icon {
        @include image-icon($image-social-pinterest-icon, 45px, 45px);
    }

    .youtube-icon {
        @include image-icon($image-social-youtube-icon, 45px, 45px);
    }

    .email-icon {
        @include image-icon($image-social-email-icon, 45px, 45px);
    }
}

.share {
    justify-content: left;

    ul {
        display: flex;
        justify-content: left;
        align-content: center;

        li > a.at300b {
            margin-left: 0.5em;

            span {
                border-radius: 50%;

                svg {
                    padding: 3px;
                }
            }
        }
    }
}

.a2a_menu a {
    color: #000 !important;
}

.a2a_svg, .a2a_count {
    border: 1px solid #eaeaea;
    border-radius: 50% !important;
    padding: 10px !important;
    width: 50px !important;
    height: 50px !important;
}

#a2a_copy_link {
    .a2a_svg, .a2a_count {
        width: 34px !important;
        height: 34px !important;
        padding: 6px !important;
    }
}

.a2a_menu {
    .a2a_svg, .a2a_count {
        width: 20px !important;
        height: 20px !important;
        padding: 4px !important;
    }
}

.a2a_menu_find_container {
    .a2a_svg, .a2a_count {
        width: 20px !important;
        height: 20px !important;
        padding: 0 !important;
        border: none !important;
    }
}
