@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';
@import '../base/mixins.scss';
@import '../base/fonts.scss';

html body {
    font-family: "franklin-gothic-urw-book",sans-serif;
}

body {
    padding-top: $header-height-mobile;
    line-height: 1.5;

    @include respond-min($large-device) {
        padding-top: $header-height-desktop;
    }
}
input, textarea, select {
    font-family: franklin-gothic-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
}
input[type="submit"] {
    //fix for iphone
    -webkit-appearance: none;
    border-radius: 0;
}
a {
    text-decoration: underline;
    cursor: pointer;

    &, & > span {
        color: #011D49;
    }

    &:hover {
        text-decoration: none;
    }

    &.disabled {
        color: #606060;
        text-decoration: none;
    }
}

nav {
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & > li {
            margin: 0;
            list-style-type: none;
        }
    }
}

p {
    line-height: 1.5em;
    width: 100%;
    word-break: break-word;
    & + p {
        margin-top: 1em;
    }
}

button {
    border: 0;
    background: 0;
}

hr {
    margin: 3em 0;
    width: 100%;
    border: 0;
    border-top: 1px solid $horizontal-line-colour;
}

// page
.page-container {
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: center;
    }
}

.wrapper {
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 100%;
    max-width: $wrapper-width;
    width: 100%;
    z-index: 50;

    @include respond-min($small-device) {
        padding: 0 12px;
    }

    @include respond-min($medium-device) {
        padding: 0 29px;
    }

    @include respond-min($extra-large-device) {
        padding: 20px;
    }

    & > .box {
        width: 100%;
    }
    * {
        box-sizing:border-box;
    }
}


section {
    width: 100%;

    .title, .list {
        flex: 0 1 100%;
    }

    .wrapper > * {
        flex: 0 1 100%;
    }

    & + section {
        margin-top: 1em;
        padding-top: 1em;
        border-top: solid 1px #d2d2d2;
    }
}


.page-contents {
    flex: 1;

    p + p {
        margin-top: 2em;
    }
}

p.required-field {
    margin-bottom: 1em;
    font-size: 14px;
    color: #606060;

    &:before {
        @include required-asterisk {
            margin-right: 5px;
        }
    }
}

label, span {
    &.required-field:after {
        @include required-asterisk {
            margin-left: 5px;
        }
    }
}

.hidden {
    display: none !important;
}

.padlarge {
    margin-top: 2em;
    margin-bottom: 2em;
}

.information {
    max-width: 520px;
    &, p {
        display: block;
        font-size: 15px;
        color: #707070;
        line-height: 20px;
        max-width: 520px;
    }

    &.textbox {
        max-width: 320px;
    }
}


.box {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    padding: 1em;

    @include respond-min($small-device) {
        padding: 1.5em;
    }

    @include respond-min($medium-device) {
        justify-content: space-between;
    }
    
    @include respond-min($large-device) {
        padding: 3em 3.5em;
    }

    & + .box {
        margin-top: 1.5em;
    }

    &.box-small {
        padding: 0.8em;

        @include respond-min($small-device) {
            padding: 1em;
        }

        @include respond-min($medium-device) {
            padding: 1.5em;
        }
    }

    &.box-padding-small {
        padding: 0.7em;

        @include respond-min($large-device) {
            padding: 1em;
        }
    }

    &.box-spacing-small {
        margin: 0.6em 0;

        @include respond-min($medium-device) {
            margin: 1em 0;
        }
    }

    &.box-spacing-large {
        margin: 2em 0;

        @include respond-min($medium-device) {
            margin: 4em 0;
        }
    }

    &.box-white {
        background-color: #ffffff;
    }

    &.box-grey {
        background-color: #F5F5F5;
    }
}

.responsive-image {
    width: 100%;
    height: auto;
}