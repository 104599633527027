@import '../base/vars.scss';
@import '../base/mixins.scss';

.account {
    section {
        padding-top: 1em;

        & + section {
            border-top: 1px solid #d2d2d2;
        }

        .title {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: flex-start;

            &:before {
                margin-right: 19px;
                float: left;
                content: '';
            }
        }

        .list {
            & > li {
                display: flex;
                flex-flow: row wrap;
            }
        }

        .page-photo {
            flex: 1;
            margin-right: 1em;

            .image {
                border: 1px solid #ECEBEB;
            }
        }

        .page-details {
            flex: 1 1 235px;
            display: flex;
            flex-direction: column;

            a {
                text-decoration: none
            }

            .page-title {
                color: #000000;
            }
        }

        .action-buttons {
            margin-top: 1em;
        }

        .amount-raised {
            display: block;
            @include account-rasied();
        }
    }


    .fund-pages {
        .title:before {
            @include image-icon($image-fund-header-icon, 45px, 55px);
        }
    }

    .event-pages {
        .title:before {
            @include image-icon($image-event-header-icon, 45px, 45px);
        }
    }

    .donations {
        .title:before {
            @include image-icon($image-donation-header-icon, 45px, 55px);
        }

        .fund-name {
            margin: .25em 0;
            margin-left: 20px;
            font-family: 'franklin-gothic-urw-demi';
            text-transform: uppercase;

            a {
                font-size: 22px;
                text-decoration: none;
                color: #000000;
            }
        }
    }
   
}
.register {
    .donation-statement {
        margin-bottom: 1em;
    }
}
