@import '../base/vars.scss';
@import '../base/media.scss';
@import '../base/mixins.scss';

.fund {
    display: flex;
    flex-direction: column;
    padding: 4em 0;

    @include respond-min($small-device) {
        padding: 3em 0;
    }

    @include respond-min($medium-device) {
        padding: 2em 0;
    }

    .wrapper > section + section {
        margin-top: 1.8em;
    }

    .box {
        margin: 0;

        + .box {
            margin-top: 2em;

            @include respond-min($large-device) {
                margin-top: 4em;
            }
        }
    }

    .action-buttons {
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;

        @include respond-min($medium-device) {
            order: 3;
        }
        /*@include respond-min($extra-large-device) {
            justify-content: center;
        }*/
    }

    .pagination {
        margin: 1em 0;
        flex: 0 1 100%;

        @include respond-min($medium-device) {
            flex: 0 1 auto;
            order: 4;
        }
    }

    .fund-title + .fund-information-donation-summary {
        margin-top: 20px;
    }

    .fund-title {
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        @include respond-min($small-device) {
            justify-content: flex-start;
        }

        a.facebook-link {
            margin-left: 20px;
            display: block;
            height: 42px;
            width: 42px;
            background-image: url($image-fund-facebook-icon);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
        }
    }

    .fund-event-date {
        flex: 0 0 100%;
        margin-bottom: 1em;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;

        &:before {
            margin-right: 18px;
            display: block;
            height: 20px;
            width: 20px;
            background: url($image-event-header-icon) 50% 50% no-repeat;
            background-size: contain;
            z-index: 1;
            content: '';
        }
    }

    .fund-featured-photo {
        flex: 0 0 100%;
        margin: 20px 0;

        @include respond-min($small-device) {
            flex: 0 0 50%;
        }

        @include respond-min($extra-large-device) {
            margin: 0;
            padding-right: 1em;
            flex: 24%;
            order: 1;
        }

        img {
            margin: 0 auto;
            display: block;
            width: 100%;
            max-width: 360px;

            @include respond-min($small-device) {
                margin: 0;
                max-width: 320px;
            }
        }

        .action-buttons {
            align-content: flex-start;

            @include respond-min($medium-device) {
                align-content: flex-start;
                justify-content: flex-start;
            }

            @include respond-min($extra-large-device) {
                align-content: center;
                justify-content: center;
            }
        }
    }

    .fund-information-donation-summary {
        flex: 0 0 100%;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;

        @include respond-min($small-device) {
            padding: 0 1em;
            flex: 0 0 50%;
        }

        @include respond-min($medium-device) {
            padding: 0 2em;
        }

        @include respond-min($large-device) {
            margin: 0;
        }

        @include respond-min($extra-large-device) {
            flex: 24%;
            order: 3;
            padding: 0;
        }

        .donate-button {
            font-size: 20px !important;
            text-align: center !important;
            margin-bottom: 10px;
            width: 100%;
            max-width: 245px;
            justify-content: center;
        }

        & > h3 {
            margin-bottom: 0.4em;
        }

        .donation-total-raised {
            @include account-rasied(48px, 'franklin-gothic-ext-cond');
            margin-bottom: 20px;
            font-family: 'franklin-gothic-ext-cond';
        }

        hr {
            margin: 20px 0;
        }
    }

    .fund-details {
        flex: 0 0 100%;
        margin-bottom: 20px;

        @include respond-min($extra-large-device) {
            flex: 0 0 48%;
            order: 2;
            padding: 0 1%;
        }

        .action-buttons {
            margin: 0;
            margin-bottom: 1em;
            justify-content: flex-start;
        }

        blockquote {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            margin-bottom: 1.2em;
            font-size: 19px;
            text-align: center;

            @include respond-min($medium-device) {
                flex-flow: row nowrap;
                justify-content: flex-start;
                text-align: left;
            }

            &:before {
                flex: 0 0 76px;
                display: block;
                height: 62px;
                width: 59px;
                background-image: url($image-fund-blockquote-icon);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                content: '';

                @include respond-min($medium-device) {
                    margin-right: 15px;
                }
            }

            span {
                flex: 0 1 auto;
            }
        }
    }


    .donations {
        flex: 0 0 100%;
        display: flex;
        //flex-flow: column; //IE just just shits all over this :(
        -ms-flex-direction: column;
        //see below for IE fixes
        justify-content: flex-start;

        @include respond-min($large-device) {
            padding-right: 1em;
            flex: 1 0 50%;
        }

        .title {
            align-items: center;
            display: flex;
            margin-bottom: 30px;

            &:before {
                margin-right: 18px;
                @include image-icon('#{$image-donation-header-icon}', 44px, 50px);
                content: '';
            }
        }

        .list {
            width: 100%;

            li {
                padding: 1em 0;

                & + li {
                    border-top: 2px solid #c4c4c4;
                }
            }
        }

        .amount-raised {
            @include account-rasied();
        }

        .donation-donator {
            margin: .25em 0;
            margin-left: 20px;
            font-family: 'franklin-gothic-urw-demi';
            text-transform: uppercase;

            a {
                font-size: 22px;
                text-decoration: none;
                color: #000000;
            }
        }

        .action-buttons {
            margin: 0 auto;
            margin-bottom: 1em;
            width: 100%;

            @include respond-min($large-device) {
                width: inherit;
                justify-content: flex-start;
            }
            /*justify-content: flex-start;*/
            .button-primary {
                font-size: 20px !important;
                text-align: center !important;
                width: 100%;
                max-width: 245px;
                justify-content: center;
            }
        }
    }

    .events {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        .title {
            align-items: center;
            display: flex;
            margin-bottom: 30px;

            &:before {
                margin-right: 18px;
                @include image-icon("#{$image-event-header-icon}", 44px, 50px);
                content: '';
            }
        }

        .list {
            flex: 1 0 100%;

            & > li {
                display: flex;
                flex-flow: row wrap;
            }
        }

        .event-image {
            flex: 0 1 312px;
            height: 176px;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: cover;

            @include respond-min($large-device) {
                flex: 1 0 100%;
                height: 230px;
            }

            & > a {
                display: block;
                height: 100%;
                width: 100%;
            }
        }

        .event-information {
            width: 100%; //IE fix
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            padding-left: 1em;

            @include respond-min($large-device) {
                padding: 0;
            }

            a.event-name {
                color: #000000;
                text-decoration: none;
            }
        }

        .amount-raised {
            @include account-rasied();
        }

        .action-buttons {
            flex: 1 0 100%;
        }
    }

    .person {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            &:before {
                margin-right: 18px;
                @include image-icon($image-fund-header-icon, 45px, 54px);
                content: '';
            }
        }
        /*        display: flex;
        flex: 1 1 100%;
        flex-flow: column wrap;
        align-items: flex-start;

        @include respond-min($large-device) {
            flex-flow: row wrap;
        }

        @include respond-min($large-device) {
            flex: 1 1 100%;
        }

        .title {
            display: flex;
            align-items: center;

            @include respond-min($large-device) {
                width: 100%;
            }

            &:before {
                margin-right: 18px;
                @include image-icon($image-fund-header-icon, 45px, 54px);
                content: '';
            }
        }*/

        .person-photo {
            margin: 0 auto;

            @include respond-min($medium-device) {
                margin: 0;
            }

            @include respond-min($large-device) {
                margin: 0 auto;
            }
        }

        .person-details {
            flex: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            @include respond-min($medium-device) {
                align-items: center;
            }
        }

        .amount-raised {
            @include account-rasied(22px);
        }

        .action-buttons {
            flex: 0 0 100%;
        }
        /*        .action-buttons {
            flex: 0 0 100%;

            @include respond-min($medium-device) {
                justify-content: flex-start;
            }

            @include respond-min($large-device) {
                justify-content: center;
            }

            a {
                text-align: center;
            }
        }*/
    }

    .events, .person {
        flex: 0 0 100%;

        @include respond-min($large-device) {
            flex: 1 100%; //0 0 420px;
        }

        @include respond-min($large-device) {
            padding-left: 1em;
            flex: 1 50%; // 0 0 420px;
            max-width: 420px;
        }
    }

    .videos {
        width: 100%;

        .title {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            &:before {
                margin-right: 18px;
                @include image-icon("#{$image-video-header-icon}", 44px, 60px);
                content: '';
            }
        }

        .action-buttons {
            flex: 100%;
        }

        iframe {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 800px;
            height: 165px;

            @include respond-min($small-device) {
                height: 340px;
            }
        }
    }

    .photos {
        width: 100%;

        .title {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            &:before {
                margin-right: 18px;
                @include image-icon("#{$image-photo-header-icon}", 44px, 54px);
                content: '';
            }
        }

        .action-buttons {
            flex: 100%;
        }

        .photo-list {
            column-width: 298px;

            & > .photo {
                img {
                    width: 100%;
                }
            }
        }
    }

    .no-results {
        width: 100%;

        &:before {
            display: block;
            margin: 0 auto;
            margin-bottom: 2em;
            height: 85px;
            width: 89px;
            background-image: url("#{$image-no-events}");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            content: '';
        }

        .label {
            margin-bottom: 1em;
            font-size: 30px;
            text-align: center;
            text-transform: uppercase;
        }

        &.donations {
            display: table;
            flex-grow: 1;

            h2, h3, h4 {
                font-family: franklin-gothic-urw-cond-book,sans-serif;
                font-weight: 400;
                margin-bottom: 0.5em;
                font-size: 30px;
                text-transform: uppercase;
            }

            p:last-child {
                margin-bottom: 1em;
            }

            &:before {
                display: none;
            }
        }
    }
}

//fixes relating to IE
@supports (display: flex) {
    .fund {
        .donations {
            flex-flow: column;
        }
    }
}
//fix for small iphone device
@supports (-webkit-overflow-scrolling: touch) {
    /*@include respond-max($small-mobile) {*/
        .fund {
            .donations {
                .title {
                    margin-top: 20px;
                }
            }
        }
    /*}*/
}